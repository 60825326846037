

import React from 'react';
import budBg from '../../assets/img/companyAdminBg/useBud.jpeg'
import daoBg from '../../assets/img/companyAdminBg/useDao.png'

function FullPageBackground(companyObj = {}) {
  const { img3File = {}, useTempleConf = {} } = companyObj
  const { useBud, useDao } = useTempleConf
  return <div
    className="full-page-background"
    style={{
      backgroundImage: img3File.url ? `url(${img3File.url})`
        : ((useBud ? `url(${budBg})` : null) || (useDao ? `url(${daoBg})` : null) || 'url(https://images.unsplash.com/photo-1499750310107-5fef28a66643?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2700&q=80)')
    }}
  />
}

export default FullPageBackground;
