import React from 'react';
import { FormGroup, Input, InputGroupAddon, InputGroupText, InputGroup, FormText } from 'reactstrap';

export const infoArr = [
  { icon: 'nc-icon nc-tv-2', title: '雲端管理系統', context: '免安裝、免自備伺服器，帳號登入即可使用' },
  { icon: 'nc-icon nc-key-25', title: 'HTTPS+資料庫加密', context: '採用HTTPS加密連線，保障傳輸安全。資料庫每日備份，安全更有保障' },
  { icon: 'nc-icon nc-atom', title: '全平台支援', context: '手機、平台、電腦，皆可使用' },
  // { icon: 'fas fa-print', title: '各式列印文件', context: '一鍵自動生成各種文件，大幅減少人力作業' },
  { icon: 'fas fa-address-card', title: '超過100間客戶愛戴', context: '成立七年，致力給客戶最好的使用體驗' },
  // { icon: 'fab fa-line', title: '專屬LINE機器人', context: '機器人即時發通知，隨時掌握重要訊息' }
];

export const formSubDomain = [
  { icon: 'nc-globe', name: 'subdomainName', placeholder: '網域名稱 (英數)', append: '.cloudoffice.tw' },
]

export const form1 = [
  // {
  //   icon: 'nc-app', name: 'webtypeId', placeholder: '類型', type: "select", options: [
  //     // { value: 'school', text: '學校管理' },
  //     // { value: 'dorm', text: '套房出租' },
  //     // { value: 'temple', text: '佛寺管理' },
  //     // { value: 'dao', text: '廟宇管理' },
  //     // { value: 'school', text: '人臉辨識機 (教育機構)' },
  //     // { value: '12', text: '人臉辨識機 (公司行號)' },
  //     // { value: '13', text: '人臉辨識機 (套房出租)' },
  //     // { value: '21', text: '差勤管理 (公司行號)' },
  //     { value: '31', text: '佛教、密宗' },
  //     { value: '32', text: '道教' },
  //   ]
  // },
  { icon: 'nc-shop', name: 'companyName', placeholder: '佛寺/宮廟名稱 (中文)' },
  ...formSubDomain,
  // { icon: 'nc-tablet-2', name: 'sn', placeholder: '機器序號 (XXX-XXXXXXXX)' },
]

export const formDemoUser = (intl, companyId) => ([
  { icon: 'nc-shop', name: 'ownedCompany', placeholder: `${companyId === 'QE5QVCTAcK' ? '佛寺' : '宮廟'}名稱 (中文)` },
  ...form2(intl),
])

export const formUser = (intl) => ([
  { icon: 'nc-single-02', name: 'username', placeholder: intl.formatMessage({ id: 'Email' }) },
])

export const formUserPass = (intl) => ([
  ...formUser(intl),
  { icon: 'nc-key-25', name: 'password', placeholder: intl.formatMessage({ id: 'Password' }), type: 'password' },
])

export const form2 = (intl) => ([
  { icon: 'nc-badge', name: 'name', placeholder: intl.formatMessage({ id: '姓名' }) },
  { icon: 'nc-mobile', name: 'phone', placeholder: intl.formatMessage({ id: '手機' }) },
  ...formUserPass(intl)
])

export const formInput = ({ icon, name, placeholder, append = '', type = 'input', options = [] }, i,
  verifySel = {}, form = {}, handleChange) =>
  <FormGroup key={`form1${name}${i}`}>
    <InputGroup>
      <InputGroupAddon addonType="prepend"
      // invalid
      >
        <InputGroupText style={{ padding: '0.375rem 0.75rem' }}>
          <i className={`nc-icon ${icon}`} />
        </InputGroupText>
      </InputGroupAddon>
      <Input
        invalid={!!verifySel[name]}
        // valid
        name={name}
        placeholder={placeholder}
        type={type === 'input' ? "text" : type}
        style={{ borderRadius: '0px' }}
        value={form[name]}
        onChange={handleChange}
      >
        {type === 'select' ? options.map(({ text, value }, i) => <option key={`opt${i}`} value={value}>{text}</option>) : null}
      </Input>
      {append ? <InputGroupAddon addonType="append">
        <InputGroupText>{append}</InputGroupText>
      </InputGroupAddon> : null}
    </InputGroup>
    {/* <FormFeedback valid>Sweet! that name is available</FormFeedback> */}
    {/* <FormText>123</FormText> */}
    {verifySel[name] === '1' ? <FormText className="text-danger">此欄位必填</FormText> : null}
    {verifySel[name] === '2' ? <FormText className="text-danger">本帳號已使用，請更換</FormText> : null}
    {verifySel[name] === '3' ? <FormText className="text-danger">本設備已使用，請更換</FormText> : null}
    {verifySel[name] === '4' ? <FormText className="text-danger">無此設備，請重新輸入</FormText> : null}
    {verifySel[name] === '5' ? <FormText className="text-danger">請輸入正確的信箱格式</FormText> : null}
    {/* <FormFeedback tooltip>Oh noes! that name is already taken</FormFeedback> */}
  </FormGroup>
