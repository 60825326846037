import Validator from 'validator';
import isEmpty from 'lodash/isEmpty';
import { REQUIRED } from './messages';

export default function validateInput(data) {
  const errors = {};

  if (Validator.isEmpty(data.username)) {
    errors.username = REQUIRED;
  }

  if (Validator.isEmpty(data.password)) {
    errors.password = REQUIRED;
  }

  return {
    errors,
    isValid: isEmpty(errors),
  };
}
