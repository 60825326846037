import React from 'react';
import { connect } from 'react-redux';
import { Message, Loader } from 'semantic-ui-react';
import { Button, Card, CardHeader, CardBody, CardFooter, Label, FormGroup, Form, Input, Container, Col, Row, } from 'reactstrap';
import { injectIntl, FormattedMessage, } from 'react-intl';

import validateInput from 'validators/login';
import { loginUser } from 'store/actions/auth';
import { formSubDomain, formUserPass, formInput, formDemoUser } from '../authDef';
import Parse from 'widget/parse';
import { fetchData, fetchJson } from '../../../widget/http2';
import myToast from 'widget/MyToast';
import FullPageBackground from '../FullPageBackground'
import Moment from 'moment';


class Login extends React.Component {
  state = {
    form: {
      username: '',
      password: '',
      name: '',
      phone: '',
      subdomainName: '',
      ownedCompany: '',
    },
    errors: {},
    isLoading: false,
    verifySel: {}
  };

  componentDidMount() {
    // console.log('componentDidMount')
    const { companyObj, history } = this.props;
    const companyId = companyObj.objectId;
    document.body.classList.toggle(companyId === 'lu6226DYO8' ? "register-page" : "login-page");
    if (companyId === 'QE5QVCTAcK' || companyId === 'EFWQgEVS5j') {
      this.setState({ form: { ...this.state.form, username: 'test@tw', password: 'test1234' } })
    }
    /*
    const { location = '' } = history;
    // console.log(location)
    if (location && location.search) {
      const obj = Object.fromEntries(new URLSearchParams(location.search));
      // console.log(obj)
      const { username, password } = obj;

      if (username && password) {
        // loginUser({ username, password }, history, companyObj)
        // history.push('/login');
        // console.log(object)
      }
    }
    */
  }

  componentWillReceiveProps(nextProps) {
    // console.log('componentWillReceiveProps')
    // const { history } = this.props;
    // if (nextProps.auth && nextProps.auth.isAuthenticated) {  ///func loginUser已有判斷與網頁跳轉，故不需要此項判斷與網頁跳轉
    //   history.push('/admin');
    // }
    if (nextProps.errors) {
      this.setState({
        errors: nextProps.errors,
        isLoading: false,
      });
    }
  }

  componentWillUnmount() {
    const { companyObj } = this.props;
    const companyId = companyObj.objectId;

    document.body.classList.toggle(companyId === 'lu6226DYO8' ? "register-page" : "login-page");
  }

  handleChange = e => {
    const { verifySel } = this.state
    const { name, value } = e.target;
    const sel = { ...verifySel }
    if (Object.keys(verifySel).length) {
      delete sel[name];
    }
    this.setState({ verifySel: sel, form: { ...this.state.form, [name]: value } });
  };

  handleSubmit = async e => {
    const { history = null, loginUser } = this.props;
    const { companyObj } = this.props;
    const { form } = this.state;
    const { username, password, subdomainName } = form;
    const companyId = companyObj.objectId;
    // if (companyId !== 'lu6226DYO8') {
    // e.preventDefault();
    // }

    // const res = await signinUser(email, password);
    // console.log(res)
    // if (res === '1') {
    //   history.push('/admin/dashboard');
    // }
    // if (this.isValid()) {
    //   this.setState({ errors: {}, isLoading: true });
    // console.log(username, password, subdomainName)
    e.preventDefault();
    const verifySel = await this.handleVerify();
    if (companyId !== 'lu6226DYO8') {
      delete verifySel.subdomainName
      delete form.subdomainName
      if (!(companyId === 'QE5QVCTAcK' || companyId === 'EFWQgEVS5j')) {
        console.log(verifySel)
        delete verifySel.phone
        delete verifySel.name
        delete verifySel.ownedCompany
        delete form.phone
        delete form.name
        delete form.ownedCompany
      }
      if (Object.keys(verifySel).length) {
        console.log(verifySel)
        this.setState({ verifySel })
        return;
      }
      // if (!username) {
      //   myToast({ type: 'warning', icon: 'exclamation icon', title: '錯誤，請輸入帳號' });
      //   // window.alert(`錯誤，請輸入帳號`)
      //   return
      // } else if (!password) {
      //   myToast({ type: 'warning', icon: 'exclamation icon', title: '錯誤，請輸入密碼' });
      //   // window.alert(`錯誤，請輸入密碼`)
      //   return
      // }
      loginUser({ ...form, loginAt: Moment(new Date()).format('YYYY-MM-DD HH:mm:ss') }, history, companyObj);
    } else {
      if (!subdomainName) {
        myToast({ type: 'warning', icon: 'exclamation icon', title: '錯誤，請輸入網域名稱' });
        // window.alert(`錯誤，請輸入網域名稱`)
        return
      }
      // const { res } = await fetchJson(`http://localhost:3000/cloud/checkcompany`, { subdomainName }, { method: 'POST' })
      const { res } = await Parse.iwCloud(`checkcompany`, { subdomainName })
        .catch(err =>
          myToast({ type: 'warning', icon: 'exclamation icon', title: `發生錯誤\n${err}` })
          // window.alert(`發生錯誤\n${err}`)
        )
      console.log(res)

      if (!res || res !== 'ok') {
        myToast({ type: 'warning', icon: 'exclamation icon', title: `錯誤，網域名稱不存在` })
        // window.alert(`錯誤，網域名稱不存在`)
        return false
      } else {
        window.location.href(`https://${subdomainName}.cloudoffice.tw/login`);
      }
    }
  };

  isValid = () => {
    const { errors, isValid } = validateInput(this.state);
    if (!isValid) {
      this.setState({ errors });
    }
    return isValid;
  };

  handleVerify = async () => {
    const { form } = this.state;
    const { ownedCompany, name, phone, username, password } = form;
    const sel = {} // 1 為空，2 為重覆
    if (!ownedCompany) {
      sel.ownedCompany = '1';
    }
    if (!name) { sel.name = '1'; }
    if (!phone) { sel.phone = '1'; }
    if (!username) { sel.username = '1'; }
    if (!password) { sel.password = '1'; }

    return sel;
  }
  render() {
    const { intl, companyObj, auth } = this.props;
    const { errors, isLoading, verifySel, form } = this.state;
    const { useTempleConf = {}, objectId: companyId } = companyObj
    const { useBud, useDao } = useTempleConf
    // const companyId = companyObj.objectId;

    // console.log(auth)

    return (<div className={companyId === 'lu6226DYO8' ? "register-page" : "login-page"}>
      <Container>
        <Row>
          <Col className="ml-auto mr-auto" lg="4" md="6">
            <Form
              // action={`https://${form && form.subdomainName}.cloudoffice.tw/login`}
              action={`/login`}
              className="form"
              method="get"
              onSubmit={this.handleSubmit}
              ref={ref => this.form = ref}
            >
              <Card className="card-login">
                <CardHeader>
                  <CardHeader>
                    <h3 className="header text-center">
                      <FormattedMessage
                        id="Login"
                        description=""
                        defaultMessage="Login"
                      />
                    </h3>
                  </CardHeader>
                </CardHeader>
                <CardBody>
                  {companyId === 'lu6226DYO8' ? <>
                    {formSubDomain.map((item, i) => formInput(item, i, verifySel, form, this.handleChange))}
                    {/* <br /> */}
                  </> : <>
                    {companyId === 'QE5QVCTAcK' || companyId === 'EFWQgEVS5j' ?
                      formDemoUser(intl, companyId).map((item, i) => formInput(item, i, verifySel, form, this.handleChange))
                      :
                      formUserPass(intl).map((item, i) => formInput(item, i, verifySel, form, this.handleChange))
                    }
                    <FormGroup check>
                      <Label check>
                        <Input defaultChecked defaultValue="" type="checkbox" />
                        <span className="form-check-sign" />
                        {/* 記住我的帳號密碼 */}
                        <FormattedMessage
                          id="Remember my account password"
                          description=""
                          defaultMessage="Login"
                        />
                      </Label>
                    </FormGroup>
                  </>}
                </CardBody>
                <CardFooter>
                  {/* <Button
                      block
                      className="btn-round mb-3"
                      color="warning"
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                   >
                    </Button> */}
                  {/* {errors && errors.loginError ? (<Message error content={errors.loginError} />) : null} */}
                  {auth && auth.errors ? (<Message error content={auth.errors} />) : null}
                  {/* {auth.errorCode === 'noauth' ? <a>註冊</a> : <a>忘記密碼</a>} */}
                  {companyId === 'EFWQgEVS5j' || companyId === 'QE5QVCTAcK' ?
                    <Button block className="btn-round mb-3"
                      // style={{ backgroundColor: companyId === 'EFWQgEVS5j' ? '#f0e1af' : '#bababa' }}
                      color={companyId === 'EFWQgEVS5j' ? 'warning' : 'secondary'}
                      onClick={e => window.location.replace(companyId === 'EFWQgEVS5j' ? 'https://bud.cloudoffice.tw' : 'https://dao.cloudoffice.tw')}
                    >
                      {`切換至 ${companyId === 'EFWQgEVS5j' ? '佛教' : '道教'}系統`}
                    </Button>
                    : null}
                  <Button block className="btn-round mb-3"
                    color={companyId === 'lu6226DYO8' ? 'success' : "primary"}
                    // href="#submit"
                    //  onClick={this.handleSubmit}
                    type='submit'
                  // onSubmit={this.handleSubmit}
                  >
                    {!isLoading ? (companyId === 'lu6226DYO8' ? '前往' :
                      <FormattedMessage
                        id="Login"
                        description=""
                        defaultMessage="Login"
                      />
                    ) : (<Loader active inverted inline size="small" />)}
                  </Button>
                </CardFooter>
              </Card>
            </Form>
          </Col>
        </Row>
      </Container>
      <FullPageBackground {...companyObj} />
      {/* <div
        className="full-page-background"
        style={{
          backgroundImage: `url(${(companyObj && companyObj.img3File && companyObj.img3File.url) || 'https://images.unsplash.com/photo-1486312338219-ce68d2c6f44d?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2704&q=80'})`,
        }}
      /> */}
    </div>);
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  // errors: state.errors,
});

export default connect(mapStateToProps, { loginUser })(injectIntl(Login));
