

import React from 'react';
import AuthLayout from 'components/auth/AuthLayout/AuthLayout';
import Login from './Login.js';

const title = 'Log In';

function action(props) {
  return {
    chunks: ['login'],
    title,
    component: (
      <AuthLayout {...props}>
        <Login {...props} />
      </AuthLayout>
    ),
  };
}

export default action;
